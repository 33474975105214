
import { Component, Prop, Vue } from "vue-property-decorator";

import * as DI from "@/utility/DependencyInjector";
@Component({
  components: {},
})
export default class QuotationLogin extends Vue {
  @Prop() private item3!: string;

  //data
  workingCategory = {
    name: "",
  };
  isShowPassword = false;
  phone = "";
  password = "";
  isLoading = true;
  passError = false;
  phoneError = false;
  RegexError = false;
  genericError = false;
  inputState: null[] | boolean[] = [null, null];
  inputIndex: { phone: number; password: number } = { phone: 0, password: 1 };
  token!: string;
  loginDisabled = false;

  //hook
  created(): void {}

  //methods
  async login(): Promise<void> {
    this.resetError();
    this.$store.commit("refreshPage");
    if (this.inputState[0] && this.inputState[1]) {
      try {
        this.isLoading = true;
        await DI.get("App").auth.userLogin(this.phone, this.password);
        //登入後的狀態管理
        this.$store.commit("LoginState", true);
        this.$store.commit("setUser", DI.get("App").auth.currentUser.data);
        if (Boolean("Notification" in window) === true) {
          try {
            await import("@/utility/Firebase").then((module) => {
              let vapidKey = "";

              if (process.env.VUE_APP_BACKEND_URL === "develop") {
                vapidKey =
                  "BEGS4Dt89plvDLEbAFlpwBYTd3YuU3JmL2CAP5PUBwmT6yEng98msDYjglfupscw8ILFcFB5UjoNTlQf41jiNdQ";
              } else if (process.env.VUE_APP_BACKEND_URL === "staging") {
                vapidKey =
                  "BFkGVz_rWwQB75rCP5qIiM5xo2m5YpvsXBZHEwSdxmZ5nGy9YyG5oU8vQCQ2i708I7zAFsAxYadwMQTOCYIjjRk";
              } else if (process.env.VUE_APP_BACKEND_URL === "production") {
                vapidKey =
                  "BFyuqAKZKI57k5O2AGVy-AwEmE_OMj7JlN9SOI3ht1VDPxVOyKsuLSqNA09_yASVPpuVtxVzElzjYTVsnqme62I";
              }
              module
                .tokenGetter(module.getMessage, { vapidKey: vapidKey })
                .then((d) => {
                  this.token = d;
                  this.createDevice();
                });
            });
          } catch (e) {
            console.log(e);
            console.log("create firebase token failed");
            this.token = "";
          }
        } else {
          this.token = "";
          try {
            await DI.get("App").device.create(this.token);
          } catch {
            console.log("device.create failed");
          }
        }
        if (
          (DI.get("App").auth.currentUser !== undefined &&
            DI.get("App").auth.currentUser.data.roleId == 0) ||
          (DI.get("App").auth.currentUser !== undefined &&
            DI.get("App").auth.currentUser.data.roleId >= 70)
        ) {
          this.isLoading = false;
          this.loginDisabled = false;
          this.$bvModal.hide("quotation-login");
          this.$router.push({
            path: "/Quotation/List",
          });
        } else {
          DI.get("App").auth.logout();
          alert("輸入的不是師傅的帳號，請重新輸入");
          this.isLoading = false;
          this.loginDisabled = false;
        }

        this.passError = false;
        this.phoneError = false;
      } catch (errorMsg: any) {
        console.log("errorMsg.message", errorMsg.message);

        switch (errorMsg.message) {
          case "Login failed: status 403":
            this.passError = true;
            this.inputState[1] = false;
            break;
          case "Login failed: status 404":
            this.phoneError = true;
            this.inputState[2] = false;
            break;
          default:
            this.RegexError = false;
            this.genericError = true;
        }
        this.isLoading = false;
        this.loginDisabled = false;
      }
      // 執行登錄操作
    } else if (this.inputState[0] && !this.inputState[1]) {
      this.passError = true;
      this.loginDisabled = false;
    } else {
      this.phoneError = true;
      this.passError = true;
      this.loginDisabled = false;
    }
  }
  async createDevice(): Promise<void> {
    try {
      await DI.get("App").device.create(this.token);
    } catch {
      console.log(`device create failed, please check firebase token`);
    }
  }
  phoneValidate(): void {
    let phoneRegex = /^09[0-9]{8}$/;
    this.inputState[this.inputIndex.phone] = phoneRegex.test(this.phone);
  }
  // 密碼驗證
  passwordValidate(): void {
    let passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/;
    //目前暫時不檢查密碼的組成，之後再打開檢查 (密碼限定6-18碼且須英文&數字)
    // this.inputState[this.inputIndex.password] = passwordRegex.test(
    //   this.password
    // );
    this.inputState[this.inputIndex.password] = true;
  }

  resetError(): void {
    this.RegexError = false;
    this.passError = false;
    this.genericError = false;
    this.phoneError = false;
    this.loginDisabled = true;
    this.isLoading = true;
  }
}
